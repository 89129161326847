@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Tamil:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --white: #fff;
  --yellow: #faa719;
  --primary: #c02a0d;
  --black: #000;
  --dark-blue: #282561;
}

body {
  font-size: 17px;
  font-family: "Noto Sans Tamil", sans-serif;
}

.showcase {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  color: var(--white);
  padding: 0 20px;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--primary-color) url(./assets/images/galexy.jpg) no-repeat
    center center/cover;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  object-fit: cover;
}

.video-container:before,
.video-container:after {
  content: "";
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.4;
}

.video-container:before {
  background: url(./assets/images/sivan.webp) no-repeat center center/cover;
}

.video-container:after {
  background-image: linear-gradient(
    90deg,
    #282561,
    #631f6d,
    #9b0067,
    #c8004f,
    #e31e28
  );
}

.showcase .audio-payer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.content {
  z-index: 2;
}

.content h1 {
  font-size: 27px;
  margin-top: 10px;
  margin-bottom: 20px;
}

/* .content audio{
  width:100%;
} */

.footer {
  padding: 20px;
  z-index: 1;
}

.footer .fa {
  margin-right: 10px;
  color: #faa719;
  color: var(--yellow);
}

.footer .left-section p a {
  color: #fff;
  color: var(--white);
  text-decoration: none;
}

.footer .left-section a.link {
  padding: 10px 30px;
  display: inline-block;
  color: #282561;
  color: var(--dark-blue);
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  margin: 10px;
  background: #fff;
  background: var(--white);
  border-radius: 50px;
}

.footer .contact-details h3 {
  color: #faa719;
  color: var(--yellow);
}

.footer .copyright a {
  color: #fff;
  color: var(--white);
}

/* radio controle */
.rhap_container {
  background-color: #c02a0d !important;
  background-color: var(--primary) !important;
  padding: 13px 37px !important;
  background-image: linear-gradient(
    90deg,
    #282561,
    #631f6d,
    #9b0067,
    #c8004f,
    #e31e28
  );
}

.rhap_progress-section {
  flex: 8 1 auto !important;
}

.rhap_container .rhap_progress-indicator,
.rhap_container .rhap_volume-indicator {
  background: #faa719;
  background: var(--yellow);
}

.rhap_container svg,
.rhap_container .rhap_current-time {
  color: #fff;
  color: var(--white);
}

.rhap_container .rhap_total-time,
.rhap_container .rhap_additional-controls,
.rhap_container .rhap_rewind-button,
.rhap_container .rhap_forward-button {
  display: none;
}

.rhap_main {
  flex-direction: unset !important;
  flex: unset !important;
}

/* Responsive */
@media (max-width: 600px) {
  body {
    font-size: 15px;
  }
  .footer .left-section a.link {
    font-size: 16px;
  }

  .showcase .logo img {
    width: 40%;
  }

  .rhap_progress-section {
    flex: 4 1 auto;
  }
}
